#search {
    margin-bottom: 100px;
    #banner {
        margin-bottom: 0;
    }
    .quicksearch {
        position: relative;
        font-size: 18px;
        width: 600px;
        margin: 0 auto!important;
        top: -28px;
        z-index: 800;
        box-shadow: $shadow;
        border-radius: 100px;
        text-align: center;
        padding: 12px 10px 10px 10px;
        
        @include mobile {
            width: 80%;
        }
        @include small-mobile {
            width: 90%;
        }
    }
    .item {
        padding: 30px;
        width: 100%;
        border-bottom: 1px solid $greyE;
        
        p {
            margin: 15px 0;
        }
        h5 {
            font-family: $cera-medium;
            color: #999;
            font-size: 16px;
        }
    }
}
#empty {
    display: none;
    text-align: center;
    max-width: 60%;
    margin: 40px auto;
    
    @include tablet {
        max-width: 75%;
    }
    @include mobile {
        max-width: 85%;
    }
    @include small-mobile {
        max-width: 90%;
    }
}