#footer {
    background: $darkBlue;
    padding: 50px 0; 
    text-align: left;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    
    .logo {
        max-width: 200px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 35px;
        left: 60px;
        
        @include tablet {
            display: block;
            top: 0;
            left: 0;
            margin: 0 auto 30px auto;
        }
        @include mobile {
            display: block;
            top: 0;
            left: 0;
            margin: 0 auto 30px auto;
        }
        @include small-mobile {
            display: block;
            top: 0;
            left: 0;
            margin: 0 auto 30px auto;
        }
    }
    h4 {
        color: $white;
        font-size: 20px;
    }
    p {
        color: $white;
        font-size: 18px;
        opacity: .5;
        transition: $transition;
        
        &:hover {
            opacity: 1;
        }
    }
    ul {
        display: inline-block;
        vertical-align: top;
        
        li {
            margin-bottom: 6px;
        }
    }
    .colA {
        margin-right: 80px;
        margin-left: 20vw;
        position: relative;
        top: 10px;
        
        @include desktop {
            margin-right: 60px;
        }
        @include small-desktop {
            margin-left: 13vw;
            margin-right: 60px;
        }
    }
    .colB {
        margin-right: 80px;
        position: relative;
        top: 10px;
        
        @include desktop {
            margin-right: 60px;
        }
        @include small-desktop {
            margin-right: 60px;
        }
    }
    .colC {
        margin-right: 80px;
        position: relative;
        top: 10px;
        
        @include desktop {
            margin-right: 60px;
        }
        @include small-desktop {
            margin-right: 60px;
        }
    }
    #social {
        position: absolute;
        right: 60px;
        top: 70px;
        
        @include tablet {
            display: block;
            position: relative;
            margin: 0 auto;
            text-align: center;
            top: 0;
            right: 0;
        }
        @include mobile {
            display: block;
            position: relative;
            margin: 0 auto;
            text-align: center;
            top: 0;
            right: 0;
        }
        @include small-mobile {
            display: block;
            position: relative;
            margin: 0 auto;
            text-align: center;
            top: 0;
            right: 0;
        }
        img {
            max-width: 36px;
            
            @include tablet {
                margin: 0 6px;
            }
            @include mobile {
                margin: 0 6px;
            }
            @include small-mobile {
                margin: 0 6px;
            }
        }
        li {
            margin-bottom: 10px;
            @include tablet {
                display: inline-block;
            }
            @include mobile {
                display: inline-block;
            }
            @include small-mobile {
                display: inline-block;
            }
        }
    }
}
#copyright {
    background: $footer;
    padding: 15px 0;
    text-align: center;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    
    p {
        color: $white;
        font-size: 14px;
        position: relative;
        top: 2px;
    }
    a {
        &:hover {
            color: $white;
        }
    }
    .legal {
        float: left;
        text-align: left;
        margin-left: 60px;
        
        @include mobile {
            float: none;
            text-align: center;
            margin-left: 0;
        }
        @include small-mobile {
            float: none;
            text-align: center;
            margin-left: 0;
        }
    }
    .credit {
        float: right;
        text-align: right;
        margin-right: 60px;
        
        @include mobile {
            float: none;
            text-align: center;
            margin-right: 0;
        }
        @include small-mobile {
            float: none;
            text-align: center;
            margin-right: 0;
        }
    }
}