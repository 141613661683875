#team {
    .members {
        width: 1000px;
        margin: 0 auto 50px auto;
        text-align: center;
        
        @include tablet {
            width: 100%;
        }
        @include mobile {
            width: 100%;
        }
        @include small-mobile {
            width: 100%;
        }
        .teamPost {
            width: 220px;
            height: 220px;
            @extend .backgroundSettingsAlt;
            margin: 10px;
            display: inline-block;
            position: relative;
            
            @include small-desktop {
                width: 250px;
                height: 250px;
            }
            @include tablet {
                width: 30%;
                height: 250px;
            }
            @include mobile {
                width: 45%;
                height: 250px;
            }
            @include small-mobile {
                width: 100%;
                height: 275px;
                margin: 10px 0;
            }
            
            .content {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(13, 26, 38, 0.9);
                padding: 25px;
                opacity: 0;
                transition: $transition;
                
                h4 {
                    color: $brightBlue;
                    margin-top: 60px;
                }
                p {
                    color: $white;
                }
            }
            
            &:hover {
                .content {
                    opacity: 1;
                }
            }
        }
    }
}