.gallery {
    h2 {
        display: none;
    }
    .carousel-cell {       
        height: 480px; 
        width: 100%;
        text-align: center; 
        background-size: cover; 
        background-position: center; 
        background-repeat: no-repeat; 
        counter-increment: gallery-cell;
        
        @include small-desktop {
            height: 450px; 
        }
        @include tablet {
            height: 400px; 
        }
        @include mobile {
            height: 350px; 
        }
        @include small-mobile {
            height: 250px; 
        }
    }
    .carousel {   
        .flickity-page-dots {
            bottom: -30px; 
            left: 0px;
            text-align: center; 
            .dot {
                border-radius: 50%; 
                width: 10px; 
                height: 10px; 
                background: $greyD; 
                opacity: 1; 
                margin: 0 5px!important;
                transition: $transition;
                
                &:hover {
                    background: $brightBlue; 
                }
            }
            .is-selected {
                background: $darkBlue; 
            }
        }
        .flickity-button {
            display: none; 
        }
        .flickity-prev-next-button {
            display: none; 
        }
        .carousel-cell:before { 
            display: none;   
        } 
    }
    &.media {
        .carousel-cell {       
            height: 500px; 
            width: 100%;
            @include tablet {
                height: 400px; 
            }
            @include mobile {
                height: 350px; 
            }
            @include small-mobile {
                height: 250px; 
            }
            img {
                display: block;
                margin: auto;
                max-height: 100%;
                @include mobile {
                    height: 100%;
                    max-height: none; 
                    object-fit: cover;
                }
                @include small-mobile {
                    height: 100%;
                    object-fit: cover;
                    max-height: none;  
                }
            }
        }
        
        .flickity-prev-next-button {
            display: block; 
        }
    }
}
