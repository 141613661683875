#products {
    #banner {
        margin-bottom: 0;
    }
    #productNav {
        ul {
            li {
                &.activeA {
                    opacity: .4;
                }
            }
        }
    }
    .productList {
        margin-bottom: 50px;
    } 
}