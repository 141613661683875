/*---------------
  GLOBAL
---------------*/
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
.bodyWrapper {
    padding-top: 60px;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
img {
    border: 0;
    max-width: 100%;
}
a {
    text-decoration: none!important;
    outline: none;
    transition: $transition;
}
button {
    text-decoration: none!important;
    outline: none;
}
.contain {
	position: relative;
}
.backgroundSettings {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
    
    @include mobile {
        background-attachment: scroll;
    }
    @include small-mobile {
        background-attachment: scroll;
    }
}
.backgroundSettingsAlt {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
}
.intrinsic-container {
    position: relative;
    height: 0;
    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.intrinsic-container-16x9 {
    padding-bottom: 50%;
}
.row {
    // @include small-mobile {
    //     padding: 0 15px;
    // }
}
.clear {
    clear: both;
}
::-moz-selection {
	background: $brightBlue;
	color: $white;
}
::selection {
	background: $brightBlue;
	color: $white;
}

//Import Typography
@import url("//hello.myfonts.net/count/380edd");
  
@font-face {font-family: 'cera-light';src: url('../../webfonts/380EDD_4_0.eot');src: url('../../webfonts/380EDD_4_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/380EDD_4_0.woff2') format('woff2'),url('../../webfonts/380EDD_4_0.woff') format('woff'),url('../../webfonts/380EDD_4_0.ttf') format('truetype');}

@font-face {font-family: 'cera-lighti';src: url('../../webfonts/380EDD_2_0.eot');src: url('../../webfonts/380EDD_2_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/380EDD_2_0.woff2') format('woff2'),url('../../webfonts/380EDD_2_0.woff') format('woff'),url('../../webfonts/380EDD_2_0.ttf') format('truetype');}
  
@font-face {font-family: 'cera-book';src: url('../../webfonts/380EDD_9_0.eot');src: url('../../webfonts/380EDD_9_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/380EDD_9_0.woff2') format('woff2'),url('../../webfonts/380EDD_9_0.woff') format('woff'),url('../../webfonts/380EDD_9_0.ttf') format('truetype');}

@font-face {font-family: 'cera-booki';src: url('../../webfonts/380EDD_8_0.eot');src: url('../../webfonts/380EDD_8_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/380EDD_8_0.woff2') format('woff2'),url('../../webfonts/380EDD_8_0.woff') format('woff'),url('../../webfonts/380EDD_8_0.ttf') format('truetype');}

@font-face {font-family: 'cera-medium';src: url('../../webfonts/380EDD_6_0.eot');src: url('../../webfonts/380EDD_6_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/380EDD_6_0.woff2') format('woff2'),url('../../webfonts/380EDD_6_0.woff') format('woff'),url('../../webfonts/380EDD_6_0.ttf') format('truetype');}

@font-face {font-family: 'cera-bold';src: url('../../webfonts/380EDD_5_0.eot');src: url('../../webfonts/380EDD_5_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/380EDD_5_0.woff2') format('woff2'),url('../../webfonts/380EDD_5_0.woff') format('woff'),url('../../webfonts/380EDD_5_0.ttf') format('truetype');}