#productNav {
    width: 100%;
    background: $darkBlue;
    padding: 12px 0;
    text-align: center;
    
    ul {
        margin-bottom: 0;
        
        li {
            display: inline-block;
            margin: 0 20px;
            transition: $transition;
            
            &:hover {
                opacity: .7;
            } 
            img {
                max-height: 22px;
                margin-right: 3px;
                display: inline-block;
            }
            h6 {
                color: $white;
                font-size: 18px;
                display: inline-block;
                position: relative;
                top: 3px;
            }
        }
    }
}

// Active linking
#products {
    #productNav {

    }
}