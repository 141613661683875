#news {
    #banner {
        margin-bottom: 0;
    }
}

.open-gallery {
	position: absolute;
	width: 50px;
	height: auto;
	left: 20px;
	bottom: 20px;
	transition: all .2s;
	&:hover {
		width: 60px;
		left: 15px;
		bottom: 15px;
		transition: all .2s;
	}
}

.fancybox-progress {
    background: $brightBlue;
}