#banner {
    height: 400px;
    position: relative;
    @extend .backgroundSettings;
    text-align: center;
    //display: flex; 
    //align-content: center;
    margin-bottom: 50px;
    background-color: $darkBlue;
    .container {
        position: relative;
        z-index: 500;
    }
    .content {
        //position: relative;
        //z-index: 500;
    }
    .overlay {
        width: 100%;
        height: 100%; 
        position: absolute;
        background: rgba(13, 26, 38, 0.5);
        top: 0;
        left: 0;
        right: 0;
        left: 0;
        z-index: 100;
    }
    h1 {
        color: $white;
        //position: relative;
        //top: 150px;
        //max-width: 90%;
        width: 90%;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        z-index: 500;
    }
    .bannerCta {
        z-index: 500;
    }
    .bgImage {
        h1 {
            color: $white;
        }
    }
    #social {
        display: inline-block;
        position: absolute;
        left: 60px;
        bottom: 10px;
        z-index: 100;
        li {
            display: inline-block;
            img {
                max-width: 36px;
            }
        }
        @include mobile {
            display: none;
        }
        @include small-mobile {
            display: none;
        }
    }
}