#company {
    #banner {
        margin-bottom: 0;
    }
    #companyIntro {
        position: relative;
        width: 100%;
        #mainImg {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 45%;
            @extend .backgroundSettingsAlt;
            
            @include small-desktop {
                width: 30%;
            }
            @include tablet {
                display: none;
            }
            @include mobile {
                display: none;
            }
            @include small-mobile {
                display: none;
            }
        }
        .content {
            padding: 50px 0 70px 50%;
            
            @include small-desktop {
                padding: 40px 0 60px 35%;
            }
            @include tablet {
                padding: 40px 0 60px 0;
            }
            @include mobile {
                padding: 40px 0 60px 0;
            }
            @include small-mobile {
                padding: 40px 0 60px 0;
            }
            
            p {
                margin: 30px 0;
            }
        }
    }
    #relatedContent {
        background: $greyE;
        padding: 70px 0 50px 0;
        
        @include mobile {
            padding: 40px 0 30px 0;
        }
        @include small-mobile {
            padding: 40px 0 30px 0;
        }
        .aboutCo {
            margin-bottom: 60px;
            text-align: center;
            
            @include mobile {
                text-align: left;
            }
            @include small-mobile {
                text-align: left;
            }
            h2 {
                margin-bottom: 20px;
            }
            p {
                max-width: 80%;
                margin: 0 auto;

                @include mobile {
                    max-width: 90%
                }
                @include small-mobile {
                    max-width: 100%;
                }
            }
        }
        .team {
            background-image: url(../img/quote.jpg);
        }
        .certs {
            background-image: url(../img/certifications.jpg);
        }
        .ssi {
            background-image: url(../img/ssi-difference.jpg);
        }
    }
}