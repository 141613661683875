#iconTrig {
    position: absolute;
    top: 15px;
    right: 16px;
    z-index: 998;
    
    .wrapper-menu {
      width: 30px;
      height: 30px;
      display: flex; 
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      transition: transform 330ms ease-out;
    }
    .wrapper-menu.open {
      transform: rotate(-45deg);  
    }
    .line-menu {
      background-color: $brightBlue;
      border-radius: 4px;
      width: 100%;
      height: 5px;
    }
    .line-menu.half {
      width: 50%;
    }
    .line-menu.start {
      transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
      transform-origin: right;
    }
    .open .line-menu.start {
      transform: rotate(-90deg) translateX(3px);
    }
    .line-menu.end {
      align-self: flex-end;
      transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
      transform-origin: left;
    }
    .open .line-menu.end {
      transform: rotate(-90deg) translateX(-3px);
    }

}