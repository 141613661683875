#contact {
    #banner {
        margin-bottom: 0;
    }
    #contactSection {
        .container-fluid {
            display: flex;
            
            @include mobile {
                display: block;
            }
            @include small-mobile {
                display: block;
            }
        }
        .contentLeft {
            background: $white;
            width: 50%;
            float: left;
            
            @include mobile {
                width: 100%;
                float: none;
            }
            @include small-mobile {
                width: 100%;
                float: none;
            }
            
            .map {
                width: 100%;
                height: 375px;
                background-color: $darkBlue;
                background-image: url(../img/map.png);
                position: relative;
                background-position: center;
                
                .directions {
                    max-width: 30px;
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                }
            }
            .content {
                padding: 60px 60px 70px 60px;
                
                @include tablet {
                    padding: 40px 40px 50px 40px;
                }
                @include mobile {
                    padding: 40px 30px 50px 30px;
                }
                @include small-mobile {
                    padding: 40px 30px 50px 30px;
                }
                h3 {
                    font-size: 35px;
                    margin-bottom: 30px;
                }
                p {
                    font-size: 22px;
                    
                    span {
                        color: $brightBlue;
                        font-family: $cera-booki;
                    }
                    a {
                        color: $darkBlue;
                        &:hover {
                            color: $brightBlue; 
                        }
                    }
                }
                ul {
                    border-top: 1.5px solid $brightBlue;
                    padding-top: 30px;
                    margin-top: 30px;
                    
                    .breakBelow {
                        margin-bottom: 20px!important;
                    }
                }
            }
        }
        .contentRight {
            background: $greyE;
            padding: 60px;
            width: 50%;
            float: left;
            
            @include tablet {
                padding: 40px;
            }
            @include mobile {
                padding: 30px;
                width: 100%;
                float: none;
            }
            @include small-mobile {
                padding: 30px;
                width: 100%;
                float: none;
            }
        }
    }
}