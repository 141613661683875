.highlight {
    position: relative;
    width: 100%;
    .mainImg {
        position: absolute;
        top: 0;
        bottom: 0;
        @extend .backgroundSettingsAlt;
        width: 40%;
        
        @include small-desktop {
            width: 30%;
        }
        @include tablet {
            width: 25%;
        }
        @include mobile {
            position: relative;
            width: 100%;
            height: 300px;
        }
        @include small-mobile {
            position: relative;
            width: 100%;
            height: 300px;
        }
    }
    .content {
        h3 {
            font-size: 36px;
        }
        p {
            margin: 30px 0;
        }
    }
    &.left {
        .mainImg {
            left: 0;
        }
        .content {
            padding: 100px 50px 120px 45%;

            @include small-desktop {
                padding: 100px 50px 120px 35%;
            }
            @include tablet {
                padding: 50px 50px 60px 30%;
            }
            @include mobile {
                position: relative;
                width: 100%;
                padding: 40px 15px 60px 15px;
            }
            @include small-mobile {
                position: relative;
                width: 100%;
                padding: 40px 15px 60px 15px;
            }
        }
    }
    &.right {
        .mainImg {
            right: 0;
        }
        .content {
            padding: 100px 45% 120px 0;

            @include small-desktop {
                padding: 100px 35% 120px 0;
            }
            @include tablet {
                padding: 50px 30% 60px 0;
            }
            @include mobile {
                position: relative;
                width: 100%;
                padding: 40px 15px 60px 15px;
            }
            @include small-mobile {
                position: relative;
                width: 100%;
                padding: 40px 15px 60px 15px;
            }
        }
    }
    &.brightBlue {
        background: $brightBlue;
        
        h3 {
            color: $white;
        }
        h5 {
            color: $white;
        }
        p {
            color: $white;
        }
    }
    &.darkBlue {
        background: $darkBlue;
        
        h3 {
            color: $white;
        }
        h5 {
            color: $white;
        }
        p {
            color: $white;
        }
    }
    &.white {
        background: $white;
        
        h3 {
            color: $darkBlue;
        }
        h5 {
            color: $darkBlue;
        }
        p {
            color: $darkBlue;
        }
    }
}