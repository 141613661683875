/* BASE */
@import 'base/_variables.scss'; 
@import 'base/_mixins.scss';
@import 'base/_base.scss';
@import 'base/_typography.scss';
@import 'base/_buttons.scss';
@import 'base/_inputs.scss'; 
@import 'base/_validations.scss'; 

/* MODULES */
@import 'modules/_bootstrap.scss'; 
@import 'modules/animate.min.css';
@import 'modules/hover.css';
@import 'modules/flickity.css';
@import 'modules/_collapse.scss';
@import 'modules/_navicon.scss';
@import 'modules/_niceSelect.scss';

/* LAYOUTS */
@import 'layouts/_nav.scss';
@import 'layouts/_footer.scss';
@import 'layouts/_banner.scss';
@import 'layouts/_error.scss';
@import 'layouts/_gallery.scss';
@import 'layouts/_companyNav.scss';
@import 'layouts/_productNav.scss';
@import 'layouts/_productCard.scss';
@import 'layouts/_industryCard.scss';
@import 'layouts/_intro.scss';
@import 'layouts/_splitView.scss';
@import 'layouts/_highlight.scss';
@import 'layouts/_tables.scss';
@import 'layouts/_cta.scss';

/* PAGES */
@import 'pages/_home.scss';
@import 'pages/_services.scss';
@import 'pages/_products.scss';
@import 'pages/_accessories.scss';
@import 'pages/_company.scss';
@import 'pages/_team.scss';
@import 'pages/_certifications.scss';
@import 'pages/_ssi.scss';
@import 'pages/_post.scss';
@import 'pages/_search.scss';
@import 'pages/_contact.scss';
@import 'pages/_quote.scss';
@import 'pages/_news.scss';
@import 'pages/_media.scss';