#services {
    .intro {
        p {
            font-size: 20px;
        }
        h2 {
            font-size: 34px;
            margin-bottom: 40px;
        }
    }
    .servicesList {
        padding-bottom: 50px;
        .service {
            padding: 30px 20px 40px 20px;
            .content {
                .imageContainer {
                    margin-bottom: 30px;
                    img {
                        height: 280px;
                        width: 100%;
                        object-fit: cover;
                    }
                }
                h4 {
                    font-family: $cera-bold;
                    color: $darkBlue;
                    font-size: 30px;
                }
                p {
                   margin: 20px 0; 
                }
            }
        }
    }
}