#quote {
    .quoteForm {
        margin-bottom: 60px;
        
        #form {
            background: $greyE;
            width: 750px;
            margin: 0 auto;
            padding: 50px 50px 30px 50px;
            
            @include tablet {
                width: 75%;
            }
            @include mobile {
                width: 90%;
                padding: 40px;
            }
            @include small-mobile {
                width: 95%;
                padding: 30px;
            }
        }   
    }
}