#error {
    background: $darkBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    
    .logo {
        position: absolute;
        top: 40px;
        left: 40px;
        max-width: 180px;
        
        @include mobile {
            max-width: 180px;
            top: 30px;
            left: 30px;
        }
        @include small-mobile {
            max-width: 160px;
            top: 30px;
            left: 30px;
        }
    }
    .content {
        position: relative;
        z-index: 990;
        text-align: center;
        max-width: 60%;
        margin: 0 auto;
        
        @include tablet {
            max-width: 70%;
        }
        @include mobile {
            max-width: 80%;
        }
        @include small-mobile {
            max-width: 90%;
        }
        
        h1 {
            color: $white;
            font-size: 44px;
        }
        p {
            font-family: $cera-book;
            color: $white;
            font-size: 36px;
            margin: 30px 0;
            
            @include mobile {
                font-size: 30px;
            }
            @include small-mobile {
                font-size: 28px;
            }
        }
        .button {
            margin: 0 auto;
            width: 130px;
            
            h5 {
                font-size: 20px;
            }
            img {
                width: 8px;
                margin-left: 10px;
                top: 5px;
            }
        }
    }
}