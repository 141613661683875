#media {
    #banner {
        margin-bottom: 0;
        min-height: 370px;
        position: relative;
        .bannerNav {
            background: $darkBlue;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 101;
            ul {
                margin: auto;
                li {
                    display: inline-block;
                    padding: 20px 10px;
                    @include mobile {
                        padding: 10px 5px;
                    }
                    @include small-mobile {
                        padding: 10px 5px;
                    }
                    a {
                        display: inline-block;
                        padding: 0 10px;
                        h5 {
                            color: #FFF;
                            display: inline-block;
                        }
                        img {
                            display: inline-block;
                            margin-top: -7px;
                            margin-right: 10px;
                        }
                        &.active {
                            opacity: 30%;
                        }
                    }
                }
            }
        }
    }
    .mediaPosts {
        padding: 60px 0;
        .post {
            margin-bottom: 30px;
            &:hover {
                cursor: pointer;
            }
            &.gallery {
                .content {
                    h3 {
                        margin: 0;
                    }
                }
            }
            &.video {
                @include mobile {
                    margin: 0 auto 20px auto;
                    max-width: 75%;
                }
                @include small-mobile {
                    margin: 0 auto 20px auto;
                    max-width: 85%;
                }
                .mainImg {
                    height: 190px;
                    @include mobile {
                        max-width: 100%;
                    }
                    @include small-mobile {
                        max-width: 100%;
                    }
                }
                .content {
                    h3 {
                        font-size: 18px;
                        margin: 0 0 20px 0;
                    }
                    a {
                        font-family: cera-bold, sans-serif;
                        color: #0d1a26;
                        display: inline-block;
                        font-size: 18px;
                        font-weight: 500;
                        img {
                            width: 6px;
                        }
                    }
                }
            }
            .mainImg {
                height: 290px;
                background-size: cover;
                position: relative;
                @include mobile {
                    margin: 0 auto;
                    max-width: 75%;
                }
                @include small-mobile {
                    margin: 0 auto;
                    max-width: 85%;
                }
                .videoIcon {
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    display: flex;
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        background: #FFF;
                        height: 15.5%;
                        width: 14%;
                        top: 42.5%;
                        z-index: 1;
                    }
                    img {
                        position: relative;
                        width: 15%;
                        z-index: 2;
                        
                    }
                }
            }
            .content {
                background: $greyE;
                height: 100%;
                padding: 30px;
                h3 {
                    margin-bottom: 20px;
                }
                p {
                    margin-bottom: 20px;
                }
                a {
                    display: inline-block;
                    h5 {
                        float: left;
                    }
                }
            }
            .modal {
                .modal-header {
                    .close {
                        width: 40px;
                    }
                }
                .modal-content { 
                    box-shadow: none;
                }
                .modal-body {
                    .row {
                        display: flex;
                        @include tablet {
                            display: block;
                        }
                        @include mobile {
                            display: block;
                        }
                        @include small-mobile {
                            display: block;
                        }
                        .col-md-6 {
                            min-height: 100%;
                            img {
                                aspect-ratio: 1/1;
                                object-fit: cover;
                                @include tablet {
                                    margin-bottom: 20px;
                                }
                                @include mobile {
                                    margin-bottom: 20px;
                                }
                                @include small-mobile {
                                    margin-bottom: 20px;
                                }
                            }
                            .imageContent {
                                position: absolute;
                                top: 40%;
                                display: flex;
                                padding: 0 30px;
                                flex-direction: column;
                                @include tablet {
                                    padding-top: 40px;
                                    padding: 0;
                                }
                                @include mobile {
                                    padding-top: 40px;
                                    padding: 0;
                                }
                                @include small-mobile {
                                    padding-top: 30px;
                                    padding: 0;
                                }
                                   
                                h2 {
                                    color: #fff;
                                }
                                p {
                                    color: #FFF;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.fade {
            &.in {
              opacity: .9;
            }
        }
    }
    .pagination {
        //display: flex;
        align-items: center;
        //flex-grow: 1;
        justify-content: space-between;
        //width: 100%;
        padding: 15px 0;
        p {
            padding-top: 10px;
        }
        .paginationNav {
            display: flex;
            padding: 10px 0;
            text-align: right;
            justify-content: end;
            a {
                display: inline-block;
                border: solid $brightBlue 2px;
                padding: 10px 12px;
                h5 { 
                    float: left;
                    font-size: 14px;
                    margin-right: 10px;
                }
                img {
                    display: inline;
                    margin-top: 2px;
                }
                &.prev {
                    margin-right: 20px;
                    h5 {
                        float: right;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

#mediaPost {
    #banner {
        .content {
            width: 90%;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: 0 auto;
            z-index: 500;
            h1 {
                margin-bottom: 20px;
                position: relative;
                transform: none;
                @include mobile {
                    font-size: 36px;
                    margin-bottom: 15px;
                }
                @include small-mobile {
                    font-size: 36px;
                    margin-bottom: 15px;
                }
            }
            h3 {
                color: $white;
                font-size: 20px;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin: 0 auto;
                z-index: 500;
                span {
                    opacity: .75;
                }
            }
        }
    }
    .gallery {
        margin-bottom: 60px;
    }
    .postContainer {
        padding-top: 40px;
        padding-bottom: 80px;
        p {
            font-size: 20px;
            margin-bottom: 20px;
        }
        ul {
            list-style-type: disc;
            padding: 15px;
            li {
                font-family: cera-light, sans-serif;
                font-size: 20px;
                padding: 5px;
            }
        }
        ol {
            li {
                font-family: cera-light, sans-serif;
                font-size: 20px;
                padding: 5px;
            }
        }
        h2 {
            margin-bottom: 30px;
        }
        .video {
            margin: 40px 0;
            @include mobile {
                margin: 20px 0;
                max-width: 100%;
            }
            @include small-mobile {
                margin: 20px 0;
                max-width: 100%;
            }
        }
        .imageContainer {
            margin: 40px 0;
            max-width: 80%;
            @include mobile {
                margin: 20px 0;
                max-width: 100%;
            }
            @include small-mobile {
                margin: 20px 0;
                max-width: 100%;
            }
            img {
                margin-bottom: 20px;
            }
            p {
                font-size: 15px;
            }
        }
    }
    .recentProjects {
        margin-bottom: 60px;
        .rowHeading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px 0;
            h2 {
                @include mobile {
                    font-size: 25px;
                }
                @include small-mobile {
                    font-size: 25px;
                }
            }
            @include tablet {
                padding: 20px 0;
            }
            @include mobile {
                display: block;
                text-align: center;
                padding: 20px 15px;
            }
            @include small-mobile {
                display: block;
                text-align: center;
                padding: 20px 15px;
            }
            .pull-right {
                &:hover {
                    color: $darkBlue;
                }
                @include mobile {
                    float: none !important;
                    font-size: 14px;
                }
                @include small-mobile {
                    float: none !important;
                    font-size: 14px;
                }
            }
            
        }
        .row {
            padding: 0 25px;
            @include tablet {
                padding: 0 15px;
            }
            @include mobile {
                padding: 0 15px;
            }
            @include small-mobile {
                padding: 0 15px;
            }
        }
        .splide_track {
            width: 100%;
        }
        .splide__slide {
            height: 250px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include mobile {
                    height: 200px;
                }
                @include small-mobile {
                    height: 170px;
                }
            }
        }
        .splide__arrow {
            background: $brightBlue;
            border-radius: 4px;
            height: 3em;
            width: 3em;
            svg {
                fill: #FFF;
            }
        }
        .splide__arrow--prev {
            left: -4em;
        }
        .splide__arrow--next{
            right: -4em;
        }
    }
}
.open-gallery {
	position: absolute;
	width: 50px;
	height: auto;
	left: 20px;
	bottom: 20px;
	transition: all .2s;
	&:hover {
		width: 60px;
		left: 15px;
		bottom: 15px;
		transition: all .2s;
	}
}

.fancybox-progress {
    background: $brightBlue;
}

.modal {
    text-align: center;
  }
  
  @media screen and (min-width: 768px) { 
    .modal:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }
  }
  
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
.modalContent {
    display: none;
    max-width:80%;
    @include tablet {
        max-width:90%
    }
    @include mobile {
        padding: 20px;
        max-width:100%
    }
    @include small-mobile {
        padding: 20px;
        max-width:100%
    }
    .imageCol {
        padding: 20px;
        width: 50%;
        @include tablet {
            width:100%
        }
        @include mobile {
            width:100%
        }
        @include small-mobile {
            width:100%
        }
    }
    .imageContent {
        width: 50%;
        .imageColContent {
            padding: 20px;
            max-width: 100%;
        }
    }
}
.fancybox-bg {
    background-color: $darkBlue;
}
.fancybox-toolbar {
    .fancybox-button {
        background: transparent;
        height: 60px !important;
        width: 60px !important;
    }
}
.fancybox-slide {
    .fancybox-button {
        background: transparent;
        height: 60px !important;
        width: 60px !important;
        &.fancybox-close-small {
            position: fixed;
        }
    }
    .fancybox-content {
        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            @include tablet {
                display: block;
            }
            @include mobile {
                display: block;
            }
            @include small-mobile {
                display: block;
            }
            img {
                margin-bottom: 30px;
                width: 100%;
            }
        }
        background: none;
        color: #FFF;
        h2 {
            color: #FFF;
        }
        p {
            color: #FFF;
        }
        .fancybox-image {
            @include mobile {
                padding: 20px;
            }
            @include small-mobile {
                padding: 20px;
            }
        }
    }
}
