.tables {
    width: 100%;
    overflow-x: auto;
    .footnote {
        font-family: $cera-booki;
        font-size: 16px;
        text-align: center;
        margin-top: 10px;
    }
    table {  
        min-width: 800px; 
        width: 100%; 
        thead {
            background-color: $darkBlue;
            color: $white;
            font-size: 16px;
            font-weight: 400;
            font-family: $cera-medium;
            border: none;
            th {
                padding: 15px 0px;
                text-align: center;
            }
            tr {
                th:first-child {
                    padding-left: 20px!important; 
                }
                th:last-child {
                    padding-right: 20px!important; 
                }
            }
        }
        tbody {
            color: $greyE;
            font-size: 16px;
            font-weight: 400;
            font-family: $cera-book; 
            color: $darkBlue;
            border: none;
            tr {
                td:first-child {
                    padding-left: 20px!important; 
                }
                td:last-child {
                    padding-right: 20px!important; 
                }
            }
            tr:nth-of-type(odd) {
                background-color: $greyE;
                
            }
            tr:nth-of-type(even) {
                background-color: $greyD;
            }
            td {
                padding: 15px 0px;
                text-align: center;
            }
        }
    }
}
