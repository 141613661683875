#home {
    #banner {
        height: 620px;
        background: url(../img/pattern.png);
        
        @include mobile {
            height: 580px;
        }
        @include small-mobile {
            height: 580px;
        }

        .container {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        h1 {
            font-family: $cera-medium;
            color: $darkBlue;
            font-size: 42px;
            top: 0;
            //margin: 130px auto 40px auto;
            margin: 0 auto 40px auto;
            max-width: 80%;
            position: relative;
            transform: none;
            
            @include tablet {
                max-width: 85%;
                font-size: 36px;
            }
            @include mobile {
                max-width: 90%;
                font-size: 32px;
                text-align: left;
            }
            @include small-mobile {
                max-width: 100%;
                font-size: 30px;
                text-align: left;
            }
        }
        &.bgImage {
            border-bottom: $brightBlue solid 4px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include mobile {
                border-bottom: none;
            }
            h1 {
                color: $white;
            }
            h5 {
                color: $white;
            }
            .button {
                cursor: default;
                &.pill {
                    width: 400px;
                    text-align: center;
                    text-transform: uppercase;
                    @include mobile {
                        margin: 0 auto 8px auto;
                        width: 260px;
                    }
                    @include small-mobile {
                        margin: 0 auto 0 auto;
                        width: 260px;
                    }
                    &:hover {
                        background: $brightBlue;
                    }
                    h5 {
                        float: none;
                        top: 0;
                        @include mobile {
                            font-size: 15px;
                            top: 0;
                        }
                    }
                }
            }
        }
        .button {
            display: inline-block;
            margin: 0 auto;
            @include mobile {
                display: block;
                margin-left: 5%;
            }
            @include small-mobile {
                display: block;
                margin-left: 5%;
            }
            h5 {
                font-size: 20px;
                @include small-mobile {
                    font-size: 15px;
                }
                @include small-mobile {
                    font-size: 15px;
                }
            }
            img {
                width: 8px;
                @include small-mobile {
                    width: 5px;
                }
            }
        }
        .homeSearch {
            color: $white;
            position: absolute;
            bottom: 20px;
            right: 60px;
            z-index: 100;
            .search {
                background: rgba(255,255,255,.25);
                border-radius: 50%;
                width: 32px;
                height: 32px;
                transition: all .3s ease-in-out;
                img {
                    max-width: 17px;
                    position: relative;
                    top: 7px;
                }
                @include mobile {
                    display: none;
                }
                @include small-mobile {
                    display: none;
                }
            }
        }
    }
    .introductionBlock {
        padding: 60px 0;
        @include tablet {
            padding: 30px 0;
        }
        @include mobile {
            padding: 20px 0;
        }
        @include small-mobile {
            padding: 20px 0;
        }
        .colContent {
            padding-right: 50px;
            p,h2 {
                margin-bottom: 20px;
            }
            @include mobile {
                padding-right: 15px;
            }
            .linkBlock {
                display: none;
                margin: 40px 0;
                @include tablet {
                    display: block;
                    margin-bottom: 30px;
                    width: 80%;
                }
                @include mobile {
                    display: block;
                    margin-bottom: 30px;
                    
                }
                p {
                    border-top: solid 1px #F2F2F2;
                    border-bottom: solid 1px #F2F2F2;
                    margin-bottom: 0;
                    &:nth-child(2) {
                        border-top: none;
                    }
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-family: cera-bold,sans-serif;
                        font-size: 18px;
                        font-weight: 400;
                        padding: 15px 0;
                        &:hover {
                            color: $darkBlue;
                            img {
                                filter: brightness(0) saturate(100%) invert(7%) sepia(10%) saturate(3928%) hue-rotate(168deg) brightness(100%) contrast(94%);
                            }
                        }
                    }
                    img {
                        max-height: 12px;
                    }
                }
            }
        }
        .linkBlock {
            margin: 40px 0;
            @include tablet {
                display: none;
                margin-bottom: 30px;
                width: 80%;
            }
            @include mobile {
                display: none;
                margin-bottom: 30px;
                
            }
            p {
                border-top: solid 1px #F2F2F2;
                border-bottom: solid 1px #F2F2F2;
                &:nth-child(2) {
                    border-top: none;
                }
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: cera-bold,sans-serif;
                    font-size: 18px;
                    font-weight: 400;
                    padding: 15px 0;
                    &:hover {
                        color: $darkBlue;
                        img {
                            filter: brightness(0) saturate(100%) invert(7%) sepia(10%) saturate(3928%) hue-rotate(168deg) brightness(100%) contrast(94%);
                        }
                    }
                }
                img {
                    max-height: 12px;
                }
            }
        }
    }
    .recentProjects {
        margin-bottom: 60px;
        @include mobile {
            margin-bottom: 15px;
        }
        .rowHeading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px 0;
            @include tablet {
                padding: 20px 15px;
            }
            @include mobile {
                display: block;
                text-align: center;
                padding: 20px 15px;
            }
            @include small-mobile {
                display: block;
                text-align: center;
                padding: 20px 15px;
            }
            h2 {
                @include mobile {
                    font-size: 25px;
                }
                @include small-mobile {
                    font-size: 25px;
                }
            }
            @include tablet {
                padding: 20px 15px;
            }
            @include mobile {
                display: block;
                text-align: center;
                padding: 20px 15px;
            }
            
            a {
                color: $brightBlue;
                transition: $transition;
                &:hover {
                    color: $darkBlue;
                }
                @include mobile {
                    float: none !important;
                    font-size: 14px;
                    padding: 5px 0;
                }
                @include small-mobile {
                    float: none !important;
                    font-size: 14px;
                    padding: 5px 0; 
                }
            }
        }
        .splide_track {
            width: 100%;
        }
        .splide__slide {
            height: 250px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include mobile {
                    height: 200px;
                }
                @include small-mobile {
                    height: 170px;
                }
            }
        }
        .splide__arrow {
            background: $brightBlue;
            border-radius: 4px;
            height: 3em;
            width: 3em;
            svg {
                fill: #FFF;
            }
        }
        .splide__arrow--prev {
            left: -4em;
        }
        .splide__arrow--next{
            right: -4em;
        }
    }
    .featuredIndustries {
        text-align: center;
        margin-bottom: 60px;
        @include tablet {
            margin-bottom: 40px;
        }
        @include mobile {
            margin-bottom: 20px;
        }
        @include small-mobile {
            margin-bottom: 20px;
        }
        .row {
            .col-lg-4:nth-child(2), 
            .col-lg-4:nth-child(5)  {
                padding-left: 0;
                @include tablet {
                    padding-left: 15px;
                    padding-right: 15px;
                }
                @include mobile {
                    padding-left: 15px;
                }
                @include small-mobile {
                    padding-left: 15px;
                }
            }
            .col-lg-4:nth-child(4),
            .col-lg-4:nth-child(7) {
                padding-right: 0;
                @include tablet {
                    padding-left: 15px;
                    padding-right: 15px;
                }
                @include mobile {
                    padding-right: 15px;
                }
                @include small-mobile {
                    padding-right: 15px;
                }
            }
        }
        .rowHeading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px 0;
            @include tablet {
                padding: 20px 15px;
            }
            @include mobile {
                display: block;
                text-align: center;
                padding: 20px 15px;
            }
            @include small-mobile {
                display: block;
                text-align: center;
                padding: 20px 15px;
            }
            h2 {
                @include mobile {
                    font-size: 25px;
                }
                @include small-mobile {
                    font-size: 25px;
                }
            }
            @include tablet {
                padding: 20px 15px;
            }
            @include mobile {
                display: block;
                text-align: center;
                padding: 20px 15px;
            }
            
            h5 {
                color: $brightBlue;
                transition: $transition;
                &:hover {
                    color: $darkBlue;
                }
                @include mobile {
                    float: none !important;
                    font-size: 14px;
                    padding: 5px 0;
                }
                @include small-mobile {
                    float: none !important;
                    font-size: 14px;
                    padding: 5px 0; 
                }
            }
        }
    }
    .splitView {
        .container-fluid {
            display: flex; 
            
            @include mobile {
                display: block;
            }
            @include small-mobile {
                display: block;
            }
        }
    }
    .splide__slide {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}