h1,h2,h3,h4,h5,h6,p {
    margin: 0;
    padding: 0;
}
h1 { //Page Titles
    font-family: $cera-bold;
    color: $darkBlue;
  	font-size: 50px;
  	font-weight: 400;
    line-height: 1.2em;
    
    @include mobile {
        font-size: 46px;
    }
    @include small-mobile {
        font-size: 42px;
    }
} 
h2 { //Headlines
    font-family: $cera-bold;
    color: $darkBlue;
    font-size: 36px;
    font-weight: 400;
    line-height: 1.2em;
    
    @include mobile {
        font-size: 34px;
    }
    @include small-mobile {
        font-size: 32px;
    }
}
h3 { //Subtitles & Cards
    font-family: $cera-bold;
    color: $darkBlue;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.2em;
}
h4 { //Nav & Card Subtitles
    font-family: $cera-medium;
    color: $darkBlue;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2em;
}
h5 { //Buttons
    font-family: $cera-bold;
    color: $darkBlue;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2em;
}
h6 { //Small Titles & Footer
    font-family: $cera-medium;
    color: $darkBlue;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2em;
}
p {
    font-family: $cera-light;
    color: $darkBlue;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4em;
}
ol {
    padding-left: 25px;
}
.book {
    font-family: $cera-book;
}
b {
    font-family: $cera-medium;
}
strong {
    font-family: $cera-medium;
}
a:link {
    color: $brightBlue;
}
a:visited {
    color: $brightBlue;
}
a:hover {
    color: $brightBlue;
}
a:active {
    color: $brightBlue;
}
figcaption {
    font-family: $cera-light;
    color: $darkBlue;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4em;
    padding: 10px 0;
}