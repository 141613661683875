#nav {
    position: fixed;
    z-index: 999;
    width: 100%;

    @include tablet {
        flex-direction: row-reverse;
        display: flex;
        flex-flow: wrap-reverse;
    }
    
    #desktopNav {
        width: 100%;
        height: 60px;
        background: $white;
        box-shadow: $shadow;
        position: relative;
        @include tablet {
            height: 110px;
            text-align: center;
        }
        .logo {
            width: 225px;
            position: relative;
            left: 30px;
            top: 13px;
            @include tablet {
                width: 250px;
                left: inherit;
                top: 23px;
            }
        }
        a {
            cursor: pointer;
        }
        #navLinks {
            position: absolute;
            top: 20px;
            right: 15px;
            @include tablet {
                position: relative;
                margin: 10px auto 0 auto;
                text-align: center;
                top:25px; right:inherit;
            }
            li {
                float: left;
                margin-left: 30px;
                
                @include small-desktop {
                    margin-left: 20px;
                }
                @include tablet { 
                    float: none;
                    display: inline-block;
                    margin-left: 20px;
                }
                h4 {
                    transition: $transition;
                    cursor: pointer; 
                    
                    @include small-desktop {
                        font-size: 16px;
                    }
                    
                    &:hover {
                        color: $brightBlue;
                    }
                }
            }
        }
        .search {
            background: #F2F2F2;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            position: absolute;
            right: 190px;
            top: 15px;
            transition: $transition;
            img {
                max-width: 17px;
                position: relative;
                top: 7px;
                left: 7px;
            }
            &:hover {
                background: $greyD;
            }
            
        }
        .quote {
            background: $brightBlue;
            font-family: $cera-medium;
            color: $white;
            width: 175px;
            text-align: center;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            padding-top: 20px;
            padding-left: 10px;
            transition: $transition;
            l-header__appt-button::before {
                background-color: #004075;
                -webkit-clip-path: polygon(1rem 0,100% 0,100% 100%,0 100%);
                clip-path: polygon(1rem 0,100% 0,100% 100%,0 100%);
                content: "";
                display: block;
                height: 100%;
                left: -15px;
                position: absolute;
                top: 0;
                width: 1rem;
            }   
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 25px;
                height: 100%;
                background: $white; 
                -webkit-transform-origin: 100% 0;
                -ms-transform-origin: 100% 0;
                transform-origin: 100% 0;
                -webkit-transform: skew(-20deg);
                -ms-transform: skew(-20deg);
                transform: skew(-20deg);
                z-index: 20;
            }
            &:hover {
                background: $blueHvr;
            }
        }
        
    } // END DESKTOP NAV 
    
    .newQuote {
        position: absolute;
        color: #fff;
        right: 0;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
        z-index: 301;
        @include tablet {
            //display: none;
            top: 0;
        }
        @include mobile {
            display: none;
        }
        @include small-mobile {
            display: none;
        }
        h5 {
            color: #fff;
            background-color: $brightBlue;
            padding: 15px 20px 15px 40px;
            transition: $transition;
        }
        &:hover {
            h5 {
                background-color: $blueHvr;
            }
        }
    }

    #mobileNav {
        width: 100%;
        .mobileNavBar {
            position: relative; 
            background-color: $white; 
            height: 60px; 
            width: 100%;
            box-shadow: $shadow;
        }
        .logo {
            width: 220px;
            position: relative;
            left: 20px;
            top: 13px;
        }
        .triggerBox {
            position: absolute;
            cursor: pointer; 
            right: 0;
            top: 0;
            bottom: 0;
            width: 80px;
            
        }
        #menuOpen {
            width: 31px;  
            cursor: pointer; 
            position: absolute; 
            top: 15px; 
            right: 15px;
        }  
        #mobileMenu {
            margin-top: 60px;
            padding-top: 20px; 
            height: 100%;  
            width: 100vw;  
            top: 0;
            z-index: 990; 
            overflow: scroll;
            position: fixed; 
            background-color: $darkBlue;   
            display: none;
            text-align: left; 
            li {
                border-bottom: solid 1px rgba(255, 255, 255, 0.25);
                cursor: pointer;
                padding: 15px 15px;
                
                &:last-child {
                    border-bottom: none;
                    a {
                        border-bottom: none;
                    }
                }
                .mobilePush {
                    &.active {
                        h4 {
                            color: $brightBlue;
                        }
                    }
                }
                span {
                    color: $brightBlue;
                    float: right;
                    font-size: 25px;
                }
                #companyNav {
                    display: none;
                    max-height: inherit;
                    padding-top: 5px;
                    .row {
                        @include small-mobile {
                            padding: 0;
                        }
                        h5 {
                            display: block;
                        }
                    }
                    h5 {
                        a {
                            display: block;
                            font-family: $cera-medium;
                            font-size: 15px;
                            margin: 0;
                            padding: 8px 0;
                            text-align: left;
                        }
                    }
                }
                &.active {
                    #companyNav {
                        display: block;
                        height: auto;
                    }
                }
            }
            
            h4 {
                font-size: 20px;
                color: $white;
            }
            .search {
                background: rgba(255, 255, 255, 0.1);
                padding: 10px 16px;
                border-radius: 100px;
                margin: 40px auto;
                text-align: center;
                h4 {
                    display: inline-block;
                    margin-bottom: 0;
                    position: relative;
                    top: 2px;
                }
                img {
                    width: 22px;
                    display: inline-block;
                    margin-right: 8px;
                    position: relative;
                    top: -1px;
                }
            }
            #social {
                li {
                    border: none;
                    display: inline-block;
                    margin: 0 5px;
                    padding: 0;
                }
                img {
                    max-width: 42px;
                }
            }
            .quote {
                font-family: $cera-medium;
                color: $white;
                background: $brightBlue;
                padding: 15px 0;
                text-align: center;
                position: absolute;
                bottom: 60px;
                left: 0;
                right: 0;
            }
        }
    } // END MOBILENAV

}

// Used with navJS.twig for mobileNav
.hideOverflow {
    overflow-y: hidden; 
}

.nav-visited {  
    color: $brightBlue !important;
}

