/*---------------
  FONTS
---------------*/
$cera-light: "cera-light", sans-serif;
$cera-lighti: "cera-lighti", sans-serif;
$cera-book: "cera-book", sans-serif;
$cera-booki: "cera-booki", sans-serif;
$cera-medium: "cera-medium", sans-serif;
$cera-bold: "cera-bold", sans-serif;
 
/*---------------   
  COLORS  
---------------*/ 
$darkBlue: #0D1A26;     
$brightBlue: #00A8E1;    
$blueHvr: #0fbffa;    
$greyD: #DDD;
$greyE: #EEE;
$white: #FFF; 
$red: #EF4949;
$footer: #16232F;
$darkBlueHvr: #060e15; 

/*---------------
  MEDIA QUERIES
---------------*/
$mobile-width: 480px;
$tablet-width: 768px;
$small-desktop-width: 992px;
$desktop-width: 1200px;
$large-desktop-width: 1440px;

/*---------------
  GENERAL
---------------*/
$shadow: 1px 1px 5px 0px rgba(0,0,0,0.1);
$transition: all .3s ease-in-out;