.productCard {
    position: relative;
    background: $greyE;
    padding: 25px 25px 35px 25px;
    transition: $transition;
    margin-bottom: 30px;
    
    .mainImg {
        width: 220px;
        height: 220px;
        @extend .backgroundSettingsAlt;
        border-radius: 50%; 
        display: block;
        margin: 0 auto 20px auto;
    }
    h4 {
        font-family: $cera-booki;
        font-size: 16px;
        color: $brightBlue;
        margin: 5px 0 0 0;
    }
    p {
        font-size: 16px;
        margin: 20px 0;
    }
    &:hover {
        background: $darkBlue;
        h3 {
            color: $white;
        }
        p {
            color: $white;
        }
        .button {
            &.darkBlue {
                h5 {
                    color: $white;
                    transition: $transition;
                }
            }      
        }
    }
    
    &.alt {
        background: $darkBlue;
        border: 2px solid $darkBlue;
        
        h3 {
            color: $white;
        }
        p {
            color: $white;
        }
        .button {
            &.darkBlue {
                h5 {
                    color: $white;   
                    transition: $transition;
                }
            }      
        }
        
        &:hover {
            background: $darkBlue;
            border: 2px solid $brightBlue;
            
            .button {
                &.darkBlue {
                    h5 {
                        color: $brightBlue;   
                    }
                }      
            }
        }
    }
}