/*------------
    FORMS
------------*/  
#form {
    margin: 0 auto;
    
    #Weight {
        display: none !important; 
    }
}

/*------------ 
    LABELS
------------*/ 
label {  
    font-family: $cera-medium;
    font-size: 20px; 
    color: $darkBlue; 
    font-weight: 400; 
    margin-bottom: 10px; 
    display: block;
}

/*-------------------------
    GENERAL INPUT STYLES
--------------------------*/ 
input[type=text] {
    font-family: $cera-book!important;
    color: $darkBlue!important; 
    font-size: 20px!important;
    font-weight: 400!important;
    background: $white!important;
    border: none;
    border-radius: 0 !important;
    outline: none;
    display: block;
    padding: 14px !important;
    width: 100%;
    margin: 5px 0 0 0!important;
    transition: $transition;
    
    &:hover {
        border-color: $greyD;
    }
    &:active, &:focus {
    border-color: $brightBlue;
  }
}
input[type=email] { 
    @extend input[type=text];
}

/*--------------
    TEXT AREA
---------------*/ 
textarea { 
    @extend input[type=text];
    height: 150px;
    resize: none;
}

/*--------------------------------
    SEARCH BAR (craft default) 
---------------------------------*/ 
.searchBar {
    input[type=search] {
        width: 70%;
        display: inline;
        margin-right: -4px; 
        color: $darkBlue;
        font-family: $cera-book;
        font-size: 15px;
        font-weight: 300;
        background: $white;
        border: none;
        border-radius: 0;
        outline: none;
        padding: 14px;
        
        @include tablet {
            width: 85%;
        }
        @include mobile {
            width: 90%;
        }
        @include small-mobile {
            width: 90%;
        }
    }
    .button {
        padding: 16px 27px;
        
        @include tablet {
            display: none;
        }
        @include mobile {
            display: none;
        }
        @include small-mobile {
            display: none;
        }
    }
}

/*--------------------
    ISOTOPE SEARCH  
---------------------*/ 
.isoSearch {
    position: relative;
    input[type=search] { 
        width: 90%;
        display: inline;
        color: $darkBlue;
        font-family: $cera-book;
        font-size: 15px;
        font-weight: 300;
        background: $white;
        border: none;
        border-radius: 0;
        outline: none;
        padding: 14px;
    }
    img { 
        width: 25px;
        position: absolute;
        right: 25px; 
        top: 13px;
        @include tablet {
            right: 10px;
        }
        @include mobile {
            right: 10px;
        }
        @include small-mobile {
            right: 10px;
        }
    }
}
.freeform-row [class*="freeform-col-"] 
.freeform-label.freeform-required:after {
    display: none;
    content: none;
}
.freeform-form-errors {
    position: absolute;
    background: none !important;
    border: none !important;
    bottom: -30px;
    margin-top: 30px;
    @include small-desktop {
        bottom: -70px;
    }
    @include tablet {
        bottom: -70px;
    }
    @include mobile {
        bottom: -70px;
    }
    @include small-mobile {
        bottom: -70px;
    }
    p {
        color: #ef4949 !important;
        font-family: cera-medium !important;
        font-size: 15px !important;
    }
}
.freeform-form-success {
    position: absolute;   
    background: none !important;
    border: none !important;
    bottom: -30px;
    margin-top: 30px;
    color: $darkBlue;
    @include small-desktop {
        bottom: -70px;
    }
    @include tablet {
        bottom: -70px;
    }
    @include mobile {
        bottom: -70px;
    }
    @include small-mobile {
        bottom: -70px;
    }
    p {
        color: $darkBlue !important;
        font-family: cera-medium !important;
        font-size: 15px !important;
    }
}
#form {
    .freeform-form-errors {
        bottom: 0;
    }
    .freeform-form-success {
        bottom: 0;
    }
}
[data-freeform-controls] {
    display: block !important;
    &>div {
        display: block !important;
        align-content: inherit !important;
        .button[type=submit] {
            float: none;
        }
    }
}