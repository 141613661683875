#accessories {
    #banner {
        margin-bottom: 0;
    }
    #productNav {
        ul {
            li {
                &.activeB {
                    opacity: .4;
                }
            }
        }
    }
    .productList {
        margin-bottom: 50px;
    }
}