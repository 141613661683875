#certifications {
    .certList {
        margin: 0 0 50px 0;
        li {
            background: $greyE;
            width: 100%;
            padding: 15px;
            display: block;
            margin-bottom: 5px;
            position: relative;
            transition: $transition;
            
            h5 {
                font-family: $cera-book;
                font-size: 20px;
                position: relative;
                top: 2px;
                padding-right: 40px;
            }
            img {
                position: absolute;
                right: 15px;
                top: 14px;
                max-width: 26px;
            }
            &.linked {
                &:hover {
                    background: $greyD;
                }
            }
        }
    }
}