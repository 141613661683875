.intro {
    text-align: center;
    margin: 80px 0;
    
    @include mobile {
        margin: 60px 0;
    }
    @include small-mobile {
        margin: 60px 0;
    }
    
    h2 {
        max-width: 75%;
        margin: 0 auto 20px auto;
        
        @include tablet {
            max-width: 80%;
        }
        @include mobile {
            max-width: 90%;
        }
        @include small-mobile {
            max-width: 100%;
        }
    }
    p {
        max-width: 75%;
        margin: 0 auto;
        
        @include tablet {
            max-width: 80%;
        }
        @include mobile {
            max-width: 90%;
        }
        @include small-mobile {
            max-width: 100%;
        }
    }
}