/*----BUTTONS----*/
.freeform-fieldtype-submit button,
.button { //Use h5 to avoid duped type styles
    text-decoration: none;
    outline: none;
    border: none;
    cursor: pointer;
    transition: $transition; 
    display: block;
    
    &:hover {
        img {
            transform: translateX(2px);
        }
    }
    
    img {
        display: block;
        left: 6px;
        top: 6px;
        //display: inline-block;
        //margin-left: 7px;
        width: 6px;
        position: relative;
        //top: 2px;
        transition: $transition; 
    }
    &.brightBlue {
        text-align: left;
        h5 {
            color: $brightBlue;
            float: left;
        }
    }
    &.darkBlue {
        text-align: left;
        h5 {
            color: $darkBlue;
            float: left;
        }
    }
    &.white {
        text-align: left;
        h5 {
            color: $white;
            float: left;
        }
    }
    &.industry {
        text-align: left;
        h5 {
            float: left;
        }
    }
    &.bannerCta {
        width: 200px;
        background: $white;
        border-radius: 100px;
        padding: 14px 36px;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -25px;
        box-shadow: $shadow;
        
        h5 {
            text-align: center;
            color: $brightBlue;
            float: left;
            position: relative;
            top: 2px;
        }
        img {
            margin-left: 2px;
            width: 8px;
            //top: 4px;
        }
    }
    &.pill {
        background: $brightBlue;
        border-radius: 100px;
        padding: 12px;
        width: 200px;
        margin: 0 auto;
        text-align: center;
        box-shadow: $shadow;
        h5 {
            color: $white;
        }
        &:hover {
            background: $blueHvr;
        }
    }
    /*&.download {
        text-align: center;
        h5 {
            color: $brightBlue;
            float: left;
        }
        //add icon styles
    }*/
    &[type=submit],.submit {
        background: $brightBlue;
        border-radius: 100px;
        padding: 10px 30px 9px 30px;
        box-shadow: $shadow;
        text-align: center;
        color: $white;
        float: left;
        font-family: cera-bold,sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2em;
        h5 {
            color: $white;
            float: left;
        }
    }
    
    //Note: extend styles for splitView and highlight cards based on tone
}