// Form validation message styling 
#validations {
    position: relative; 
    margin-bottom: 50px;  
    p {  
        font-family: cera-medium;
        font-size: 15px; 
    }
    #thanks { 
        display: none; 
        position: absolute;   
        margin-top: 25px; 
        color: $darkBlue;
    }    
    #errorMessage {
        display: none;  
        position: absolute; 
        margin-top: 30px; 
        color: $red;
    }        
} 
.freeform-errors {
    display: none !important;  
}  


// Add red border around text inputs if parsley validation returns errors. 
.freeform-has-errors,
input.parsley-error,   
select.parsley-error,  
.nice-select.parsley-error,     
textarea.parsley-error,
p.parsley-error {
    border-color: $red !important; 
    border-style: solid !important;
    background-color: rgba(239, 73, 73, 0.1);     
    border-width: 1px;
} 