#companyNav {
    display: none;
    text-align: center;
    position: relative;
    height: 60px;
    max-height: 60px;
    padding-top: 20px; 
    overflow: hidden;
    background: $darkBlue;
    width: 100%; 
    
    h5 {
        display: inline-block;
        font-family: $cera-medium;
        font-size: 18px;
    }
    a {
        margin: 0 15px;
        color: $white;
        
        &:hover {
            color: $brightBlue;
        }
    }
}