.cta {
    width: 100%;
    padding: 100px 0;
    text-align: center;
    position: relative;
    .content {
        position: relative;
        z-index: 200;
        h3 {
            font-size: 42px;
            margin-bottom: 20px;
        }
    }
    &.light {
        background: $greyE;
    }
    &.dark {
        background: $darkBlue;
        
        h3 {
            color: $white;
            margin-bottom: 40px;
        }
        .button {
            background: $white;
            align-items: center;
            justify-content: center;
            display: flex;
            h5 {
                color: $brightBlue;
            }
            img {
                right: 8px;
                top: 0;
                width: 8px;
            }
        }
    }
    &.bgImage {
        padding: 140px 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .overlay {
        width: 100%;
        height: 100%; 
        position: absolute;
        background: rgba(13, 26, 38, 0.5);
        top: 0;
        left: 0;
        right: 0;
        left: 0;
        z-index: 100;
    }
}