#postElements {
    //padding-bottom: 50px;
    .postBody {
        //margin: 20px 0;
        
        p {
            margin: 25px 0;
        }
    }
    .sectionTitle {
        //margin: 20px 0;
        margin-top: 20px;
    }
    .gallery {
        //margin: 40px 0 70px 0;
        .carousel {
            margin-bottom: 30px;   
        }
    }
    .splitContainer {
        display: flex; 
            
        @include mobile {
            display: block;
        }
        @include small-mobile {
            display: block;
        }
    }
    .video {
        //margin: 40px 0;
    }
    .list {
        //margin: 40px 0;
        
        h3 {
            margin-bottom: 15px;
        }
        ul {
            list-style-type: none;
            padding-left: 15px;
            li {
                @extend p;
            }
            li:before {
                content: "•";
                counter-increment: li;
                padding-right: 5px;
                color: $brightBlue;
            }
        }
    }
    .tables {
        //margin: 40px 0;
    }
    .relatedIndustries {
       // margin: 40px 0;
        margin-bottom: -15px;
        .header {
            position: relative;
            margin-bottom: 30px;
            .darkBlue {
                position: absolute;
                right: 0;
                top: 15px;
                
                @include mobile {
                    position: relative;
                    top: 0;
                    margin-top: 12px;
                }
                @include small-mobile {
                    position: relative;
                    top: 0;
                    margin-top: 12px;
                }
            }
        }
    }
    .relatedProducts {
        //margin: 40px 0;
        margin-bottom: -15px;
        .header {
            position: relative;
            margin-bottom: 30px;
            .darkBlue {
                position: absolute;
                right: 0;
                top: 15px;
                
                @include mobile {
                    position: relative;
                    top: 0;
                    margin-top: 12px;
                }
                @include small-mobile {
                    position: relative;
                    top: 0;
                    margin-top: 12px;
                }
            }
        }
    }
    .spacer {
        width: 100%;
        background: none;
        display: block;
        
        &.standard {
            height: 60px;
            
            @include tablet {
                height: 40px;
            }
            @include mobile {
                height: 30px;
            }
            @include small-mobile {
                height: 30px;
            }
            
        }
        &.small {
            height: 40px;
            
            @include tablet {
                height: 30px;
            }
            @include mobile {
                height: 30px;
            }
            @include small-mobile {
                height: 30px;
            }
        }
    }
}