.svCard {
    width: 50%; 
    float: left; 
    
    @include mobile {
        width: 100%; 
        float: none;
    }
    @include small-mobile {
        width: 100%;
        float: none;
    }
    
    .featureImage {
        width: 100%;
        height: 275px;
        @extend .backgroundSettingsAlt;
    }
    .content {
        padding: 40px 30px 50px 30px;
        
        h3 {
            font-size: 36px;
        }
        p {
            margin: 20px 0;
        }
    }
    &.brightBlue {
        background: $brightBlue;
        
        h3 {
            color: $white;
        }
        p {
            color: $white;
        }
    }
    &.darkBlue {
        background: $darkBlue;
        
        h3 {
            color: $white;
        }
        p {
            color: $white;
        }
    }
    &.white {
        background: $white;
        
        h3 {
            color: $darkBlue;
        }
        p {
            color: $darkBlue;
        }
    }
}