.industryCard {
    width: 100%;
    height: 300px;
    @extend .backgroundSettingsAlt;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    @include tablet {
        margin-bottom: 20px;
    }
    @include mobile {
        margin-bottom: 20px;
    }
    @include small-mobile {
        margin-bottom: 20px;
    }
    &:hover {
        h4 {
            bottom:0;
            top: 0; 
            align-items: center;
            justify-content: center;
            display: flex;
            height: 100%;
        }
    }
    h4 {
        color: $white;
        font-size: 25px;
        text-align: center;
        width: 100%;
        background: rgba(13, 26, 38, 0.9);
        padding: 20px 0;
        position: absolute;
        top: 75%;
        bottom: 0;
        left: 0;
        right: 0;
        transition: $transition;
        
        @include small-desktop {
            font-size: 22px;
        }
    }
    &:hover {
        h4 {
            background: rgba(13, 26, 38, 0.9);
        }
    }
}